( function() {

    document.addEventListener("DOMContentLoaded", () => {

        const showRoomFilter = document.getElementById("showroom-filter");

        var directoryItems = document.querySelectorAll('.directory__item');

        if (showRoomFilter) {
            showRoomFilter.addEventListener('change', function () {
                if (this.checked) {
                    
                    directoryItems.forEach(function (item) {
                        if ( item.dataset.showroom != 'true') {
                            item.classList.add('hide');
                        }
                    });

                } else {
                    directoryItems.forEach(function (item) {
                        item.classList.remove('hide');
                    });
                }
            });
        }

    });

}() );